<script setup lang="ts">
    import DeliveryStatusChip from "@/components/common/chips/DeliveryStatusChip.vue";
    import PaymentMethodChip from "@/components/common/chips/PaymentMethodChip.vue";
    import PaymentStatusChip from "@/components/common/chips/PaymentStatusChip.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import { computed } from "vue";

    const props = defineProps<{
        order: OrderDetails;
    }>();
    const formattedTelephone = computed(() => {
        const phone = props.order.customer.telephone;
        return "0" + phone.slice(3);
    });

    const getDay = computed(() => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        return props.order.timeslot.day.toLocaleDateString("nl-NL", options);
    });

    const parseTime = (time: string) => {
        return time.slice(0, -3);
    };
</script>

<template>
    <v-card class="mb-5" title="Bestel informatie">
        <v-card-text>
            <v-table theme="light">
                <tbody>
                    <tr>
                        <td>Bestelnummer</td>
                        <td>
                            {{ order.orderId }}
                        </td>
                    </tr>
                    <tr>
                        <td>Naam klant</td>
                        <td>{{ order.customer.name }}</td>
                    </tr>
                    <tr>
                        <td>Opmerking(Klant)</td>
                        <td>
                            {{
                                order.remarks.customerRemark || "Geen opmerking"
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>Telefoon</td>
                        <td>
                            <a
                                :href="'tel:' + formattedTelephone"
                                class="text-black"
                            >
                                {{ formattedTelephone }}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Mailadres</td>
                        <td>
                            <a
                                :href="'mailto:' + order.customer.email"
                                class="text-black"
                            >
                                {{ order.customer.email }}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalmethode</td>
                        <td>
                            <PaymentMethodChip
                                :method="order.paymentMethod"
                                text="label"
                            ></PaymentMethodChip>
                        </td>
                    </tr>
                    <tr>
                        <td>Betaalstatus</td>
                        <td>
                            <payment-status-chip
                                :status="order.paymentStatus"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Bezorgstatus</td>
                        <td>
                            <delivery-status-chip
                                :delivered="order.state.delivered"
                                :deliver="order.deliver"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Dag</td>
                        <td>{{ getDay }}</td>
                    </tr>
                    <tr>
                        <td>Tijdslot</td>
                        <td>
                            {{ parseTime(order!.timeslot.start) }}
                            -
                            {{ parseTime(order!.timeslot.end) }}
                        </td>
                    </tr>
                    <tr>
                        <td>Aflevermethode</td>
                        <td v-if="order!.deliver">Bezorging</td>
                        <td v-else>Afhalen</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
