<script lang="ts" setup>
    import { useRoute } from "vue-router";
    import { computed, ref } from "vue";
    import type { AxiosResponse } from "axios";
    import SumupPinDialog from "@/components/dialogs/pin/SumupPinDialog.vue";
    import { OrderDetails } from "@/models/OrderDetails";
    import { useDisplay } from "vuetify";
    import orderService from "@/services/OrderService";
    import GlobyAppBar from "@/components/common/GlobyAppBar.vue";
    import PrintOrderDialog from "@/components/dialogs/printing/PrintOrderDialog.vue";
    import CustomFieldsCard from "@/components/orderDetail/CustomFieldsCard.vue";
    import ActionsCard from "@/components/orderDetail/ActionsCard.vue";
    import ProductsTable from "@/components/orderDetail/ProductsTable.vue";
    import { useAuthStore } from "@/store/auth";
    import ConfirmDeletionDialog from "@/components/dialogs/ConfirmDeletionDialog.vue";
    import OrderInformationCard from "@/components/orderDetail/OrderInformationCard.vue";
    import DeliveryInformationCard from "@/components/orderDetail/DeliveryInformationCard.vue";
    import PickupInformationCard from "@/components/orderDetail/PickupInformationCard.vue";

    const loading = ref<boolean>(true);
    const notFound = ref<boolean>(false);
    const route = useRoute();

    const order = ref<OrderDetails>();

    const authStore = useAuthStore();

    orderService.getOrderById(parseInt(route.params.id as string)).then(
        (res: AxiosResponse<OrderDetails>) => {
            // Do some transforming of the data
            res.data.timeslot.day = new Date(
                res.data.timeslot.day as unknown as string,
            );

            order.value = res.data;
            loading.value = false;
        },
        () => {
            notFound.value = true;
            loading.value = false;
        },
    );

    //-- Printing stuff
    const printDialog = ref<boolean>(false);
    const onPrint = () => {
        // Open print dialog
        printDialog.value = true;
    };

    const pageTitle = computed(() => {
        if (smAndDown.value) {
            return "";
        }
        if (loading.value) {
            return "Bestelling laden...";
        }
        if (notFound.value) {
            return "Onbekende bestelling";
        } else {
            return "Bestelling " + order.value!.orderId;
        }
    });

    //-- Pinning stuff
    const pinDialog = ref<boolean>(false);

    const { smAndDown } = useDisplay();

    const allowPinDialog = computed((): boolean => {
        if (order.value == undefined) return false;
        if (order.value?.paymentStatus !== "open") return false;
        if (order.value?.paymentMethod.type !== "card") return false;
        return true;
    });

    const allowMarkDelete = computed((): boolean => {
        // Only admins are allowed to change the delete state
        return authStore.hasRole("ROLE_ADMIN");
    });

    const allowChangePaymentState = computed((): boolean => {
        // Admins can always mark an order as paid if the payment method is not Mollie
        if (authStore.hasRole("ROLE_ADMIN")) {
            if (order.value?.paymentMethod.type !== "online") {
                return true;
            } else {
                return false;
            }
        }

        // User can only mark an order as paid if the payment method is cash
        if (order.value?.paymentMethod.type === "cash") {
            return true;
        }
        return false;
    });

    const onPin = () => {
        // Open sumup dialog
        pinDialog.value = true;
    };

    const onMarkPaid = () => {
        // Mark order as paid
        const newVal = order.value?.paymentStatus == "open" ? "paid" : "open";
        orderService.alterOrderState(
            order.value!.id,
            undefined,
            newVal == "paid",
        );
        order.value!.paymentStatus = newVal;
    };

    const onMarkDelivered = () => {
        // Mark order as delivered
        orderService.alterOrderState(order.value!.id, {
            delivered: !order.value!.state.delivered,
        });
        order.value!.state.delivered = !order.value!.state.delivered;
    };

    const deleteDialog = ref<boolean>(false);

    const onMarkDelete = () => {
        // Open delete dialog if order is active
        if (order.value!.state.deleted) {
            // Order is already deleted, so undo deletion
            onConfirmDelete();
            order.value!.state.deleted = false;
            return;
        }
        deleteDialog.value = true;
    };

    const onConfirmDelete = () => {
        // Delete order
        orderService.alterOrderState(order.value!.id, {
            deleted: !order.value!.state.deleted,
        });
        order.value!.state.deleted = true;
    };
</script>

<template>
    <v-container fluid>
        <globy-app-bar :title="pageTitle" detail="/orders/overview">
            <template #actions>
                <v-spacer />
                <v-btn
                    v-if="allowPinDialog"
                    prepend-icon="mdi-contactless-payment"
                    variant="tonal"
                    text="Pinnen"
                    class="mr-2"
                    @click="onPin"
                />
                <v-btn
                    v-if="!smAndDown"
                    prepend-icon="mdi-printer"
                    variant="tonal"
                    text="Printen"
                    @click="onPrint"
                />
            </template>
        </globy-app-bar>
        <template v-if="!loading">
            <v-row v-if="notFound">
                <v-col>
                    <v-alert text="Bestelling niet gevonden" type="error" />
                </v-col>
            </v-row>
            <template v-else-if="order">
                <v-row v-if="order.state.deleted">
                    <v-col>
                        <v-alert type="error"
                            >Deze bestelling is als verwijderd gemarkeerd. Deze
                            moet dus niet meer bezorgd worden!
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <print-order-dialog v-model="printDialog" :order="order" />
                    <sumup-pin-dialog v-model="pinDialog" :order="order" />
                    <confirm-deletion-dialog
                        v-model="deleteDialog"
                        @delete="onConfirmDelete"
                    />
                    <v-col>
                        <order-information-card :order="order" />
                        <delivery-information-card
                            v-if="order.deliver"
                            :fulfillment="order.fulfillment"
                        />
                        <pickup-information-card
                            v-else-if="!order.deliver"
                            :fulfillment="order.fulfillment"
                        />
                    </v-col>
                    <v-col>
                        <products-table :order="order!" class="mb-5" />
                        <custom-fields-card
                            v-if="order!.customFields!.length > 0"
                            :fields="order!.customFields"
                            class="mb-5"
                        />
                        <actions-card
                            :order="order!"
                            :enable-pin="allowPinDialog"
                            :enable-mark-delete="allowMarkDelete"
                            :enable-mark-paid="allowChangePaymentState"
                            @print="onPrint"
                            @pin="onPin"
                            @delete="onMarkDelete"
                            @paid="onMarkPaid"
                            @delivered="onMarkDelivered"
                        />
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>
</template>
