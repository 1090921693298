import OrdersOverviewPage from "@/views/orders/OrdersOverviewPage.vue";
import OrderScannerPage from "@/views/orders/OrderScannerPage.vue";
import OrderDetailsPage from "@/views/orders/OrderDetailsPage.vue";
import OrderCreatePage from "@/views/orders/OrderCreatePage.vue";

export const orderRoutes = [
    {
        path: "/orders/overview",
        component: OrdersOverviewPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/create",
        component: OrderCreatePage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/scan",
        component: OrderScannerPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
    {
        path: "/orders/:id",
        name: "orderDetail",
        component: OrderDetailsPage,
        meta: {
            requiresAuth: true,
            roles: [],
            onProd: true,
        },
    },
];
